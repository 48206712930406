import * as React from "react";
import ProjectSection from "../components/ProjectSection";
import { Link } from "gatsby";
import LinkArrowSvg from "../images/link-arrow";
import ClientsSection from "../components/home/ClientSection";
import Banner from "../components/Banner";
// import KnowMoreSection from "../components/KnowMoreSection";
import Faq from "../components/Faq";
import { Seo } from "../components/seo";
import LetsConnect from "../components/LetsConnect";
import KnowAbout from "../components/KnowAbout";
import WhatWeAre from "../components/WhatWeAre";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
const Questions = [
  "What services does your UI UX design agency in Mumbai offer?",
  "Which industries does your UI UX design company in Mumbai serve?",
  "How do you ensure your UI UX designs align with our brand identity and goals?",
  "What is the standard timeline for a UI UX design project?",
  "How can I contact your web design agency in Mumbai?",
];

const MumbaiPage = () => {
  const banner = {
    title: `UI UX Design Agency in Mumbai`,
    content: "Our mission is to drive research-led revolution across industries and improve user experience. As a leading UI UX design agency in Mumbai, we shape success with flawless precision.",
    img: ["why-octet-mumbai.webp"],
    imageTitle: "UI UX Design Company, Mumbai",
    imageAlt: "Octet Design Studio- Leading UI UX Design Agency in Mumbai",
  };
  const projectData = [
    {
      title: "Unilogix App",
      desc: "A Logistics Insights Application",
      Industry: "Logistics",
      firstAlt: "Mobile App UI UX Design to track logistics performance",
      firstTitle: "Mobile UI Design to Track Logistics",
      secondAlt: "Mobile App UI UX Design to check order details",
      secondTitle: "Mobile UI Design to View Order Details",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp"],
    },
    {
      title: "Ship Delight",
      desc: "An E-Commerce Logistic Website",
      Industry: "Logistics",
      firstAlt: "UI UX Design to check the real time shipment status",
      firstTitle: "UI Design to Track Shipment Status",
      secondAlt: "Dashboard UI UX Design to check NDR Status",
      secondTitle: "NDR Status's UI Screen",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp"],
    },
  ];
  const faqData = [
    {
      para: [
        `Our goal is to create exceptional digital experiences for our clients. Our website design company in Mumbai provides a wide range of UI UX design services, including <a href="/user-research/" title="User Research Services">User Research</a>, <a href="/ui-ux-designing/" title="Usability Testing Services">UI UX Design</a>, <a href="/usability-testing/" title="Usability Testing Services">Usability Testing</a>, <a href="/ui-development/" title="UI Development Services">UI Development</a> and <a href="/services/" title="Our UI UX Design Services">many more</a>.`,
      ],
    },
    {
      para: [
        `We serve clients across various industries, including e-commerce, healthcare, finance, technology, and more. Our adaptable design solutions cater to diverse business needs.`,
      ],
    },
    {
      para: [
        `Octet prioritizes understanding your brand and business objectives before initiating any design work. Our team collaborates closely with you to ensure our designs align perfectly with your vision and goals.`,
      ],
    },
    {
      para: [
        `Our project timelines can vary depending on complexity and scope. We work closely with clients to establish realistic timelines and ensure timely project delivery.`,
      ],
    },
    {
      para: [
        `You can fill out the <a href="/contact-us/" title="Contact Us">contact us</a> form through our website or by connecting us with the details provided on the page. We're always ready to discuss your UI UX Design needs.`,
      ],
    },
  ];
  const lastLength = projectData;
  const info = {
    title: "Create Impact with the best UI UX Agency in Mumbai",
    link: "/contact-us/",
    text: "Contact Us",
  };
  const whatWeAreData = {
    title: "Design Solutions by our <span class='h1-span'>UI UX Design Company in Mumbai</span>",
    des: "We are a full-service web design agency in Mumbai that helps companies succeed by providing a unique combination of innovative designs and development solutions.",
    items: [
      {
        title: "User Research",
        content:
          "Octet utilizes various techniques to collect data and gain insights that help us create products that meet our customers' needs. Our UI UX design company in Mumbai aims to ensure that your product exceeds your expectations.",
        link: "/user-research/",
        linkText: 'To achieve long-term benefits, our <a href="/user-research/" title="User Research Agency">User Research Agency</a> streamlines your resource-intensive processes.'
      },
      {
        title: "UI Development",
        content:
          "Our website designing company in Mumbai delivers exceptional user experiences and ensures that every design is meticulously transformed into a template with captivating visual components fully integrated into the Java framework.",
        link: "/ui-development/",
        linkText: 'Our <a href="/ui-development/" title="UI Development Company">UI Development Company</a> uses proficiency in multiple programming languages to create functional user interfaces.'
      },
      {
        title: "UI UX Designing",
        content:
          "Being the best UI UX design agency in Mumbai, we systematically create interactive, aesthetically pleasing interfaces that guarantee a seamless experience.",
        link: "/ui-ux-designing/",
        linkText: 'For better performance, our <a href="/ui-ux-designing/" title="User Research Agency">UI UX Design Company</a> may create personalized solutions for your company.'
      },
      {
        title: "Usability Testing",
        content:
          "We test your products using variuos tools and techniques which allows us to identify areas for improvement and ensure consistency across all products.",
        link: "/usability-testing/",
        linkText: 'To create an interface that is easy to use, our <a href="/usability-testing/" title="usability testing company">usability testing company</a> monitors how you use it & spots problems.'
      },
    ],
    mainLink: {
      link: "/services/",
      text: "View All UI UX Services",
    },
  };
  const faqDes = `We have compiled frequently asked questions to provide informative answers about our UI UX design agency in Mumbai, the design process, and pricing. If you still have any questions, don’t hesitate to <a href="/contact-us/" title="Contact Us"> contact us</a>.`;
  const knowAboutDes = [
    "Octet is a prominent website designing company in Mumbai. We transform SaaS, Enterprise, and B2B products through a wise, proactive, and proficient approach. Our flexible, lean, and agile UI UX design services empower businesses to embark on a revolutionary journey and realize their full potential. We deliver results beyond expectations with unmatched expertise and the latest industry best practices.",
    // "With our adaptable, streamlined, and agile UI UX process, we empower businesses to embark on a transformative voyage and unleash their inherent capabilities.",
  ];
  const knowAboutTitle = 'Know Octet: Top Web Design Agency in Mumbai';
  const commonContent = {
    title: "Transform Digitally with our Website Design Company in Mumbai",
    para: ['Our diverse experience has boosted the graphs of many organizations that use our full range of UI UX design services to fulfill quality requirements and deliver on time.'],
    content: [
      {
        title: "Enhanced User Experience",
        text: "To enhance users' satisfaction and engagement, our UX UI agency ensures that interactions are effortless and straightforward.",
      },
      {
        title: "High Conversion Rates",
        text: "Our UI UX designers arrange pieces according to the user experience and guide our clients easily to optimize designs and increase conversion.",
      },
      {
        title: "Data-driven Iterations",
        text: "We ensure that designs adapt to shifting user expectations by iterating and improving them in response to user input and data.",
      },
      {
        title: "Scalable Approaches",
        text: "Our website design company in Mumbai produces designs that are fit for the future, adjusting to changing business needs while staying true to your vision.",
      }
    ],
  };
  const commonContent1 = {
    title: "Growth-driven Design Process",
    para: ['Being the leading UI UX Design Company in Mumbai, we create remarkable experiences via innovation and personalization'],
    content: [
      {
        title: "1. Briefing",
        text: "To establish the nature of our collaboration, our UI UX design agency in Mumbai carries out design audits, competitive analyses, discussions, and usability testing that clarify product and business difficulties.",
      },
      {
        title: "2. Defining",
        text: "We determine your target market by interacting with your customers and utilizing various techniques to grasp their issues fully. As a team, we define the project, set deadlines, write down objectives, and decide on metrics to help you succeed.",
      },
      {
        title: "3. Strategizing",
        text: "Our web design agency in Mumbai strategizes the user experience flow, develops guidelines, and structures information architecture to organize your website's content and effectively achieve its purpose.",
      },
      {
        title: "4. Sketching",
        text: "We formulate an effective visual hierarchy for your app or website to facilitate comprehension. We aim to provide you with a visually appealing, user-friendly interface that enhances the user experience and maximizes your product's potential.",
      },
      {
        title: "5. Designing & Testing",
        text: "We develop a prototype and test the end product's functionality after finalizing the layout and designs. This enables our website design company in Mumbai to evaluate the final product, ensuring it meets the required standards and specifications.",
      }
    ],
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What services does your UI UX design agency in Mumbai offer?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our goal is to create exceptional digital experiences for our clients. Our website design company in Mumbai provides a wide range of UI UX design services, including User Research, UI UX Design, Usability Testing, UI Development and many more."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Which industries does your UI UX design company in Mumbai serve?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "We serve clients across various industries, including e-commerce, healthcare, finance, technology, and more. Our adaptable design solutions cater to diverse business needs."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How do you ensure your UI UX designs align with our brand identity and goals?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Octet prioritizes understanding your brand and business objectives before initiating any design work. Our team collaborates closely with you to ensure our designs align perfectly with your vision and goals."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What is the standard timeline for a UI UX design project?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Our project timelines can vary depending on complexity and scope. We work closely with clients to establish realistic timelines and ensure timely project delivery."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How can I contact your web design agency in Mumbai?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "You can fill out the contact us form through our website or by connecting us with the details provided on the page. We're always ready to discuss your UI UX Design needs."
                    }
                  }
                ]
              }
            `}
        </script>
      </Helmet>
      <Layout>
        <Banner
          content={banner}
          page={"ahmedabad-home"}
          location="402, Town Centre 2, Opp Times Square, Marol,  Andheri (E), Mumbai – 400059"
        />
        <WhatWeAre data={whatWeAreData} />
        <KnowAbout des={knowAboutDes} title={knowAboutTitle} imageAlt={"Octet's Logo- UI UX Design Company in Mumbai"} imageTitle={"UI UX Design Agency's Logo"} />
        <CommonServiceComponent data={commonContent} mode={"grey"} nopara />
        <CommonServiceComponent data={commonContent1} mode={"light"} list='single' nopara />
        <section className="project-section lg:py-[200px] md:pb-[125px] md:pt-[140px] pt-[100px] pb-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
          <div className="container">
            <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
              Our Projects in UX UI Design
            </h2>
          </div>
          {projectData.map((data, index) => (
            <div key={index}>
              <ProjectSection num={index} lastLength={lastLength} data={data} />
            </div>
          ))}
          <div className="container lg:mt-[-100px] md:mt-[-35px] mt-[-40px]">
            <Link
              title="UI UX Design Portfolio"
              to="/projects/"
              className="animated-link font-medium font-heading text-[20px] md:text-[22px] lg:text-[30px] md:leading-[1.33] leading-[normal] tracking-[.05em] flex items-center gap-[32px] md:gap-[38px]"
            >
              <p className="max-w-[262px] md:max-w-none w-auto font-heading">
                See more work by our UI UX design agency{" "}
              </p>
              <span>
                <LinkArrowSvg />
              </span>
            </Link>
          </div>
        </section>
        <ClientsSection title={'Trusted by Clients Worldwide'} />
        <LetsConnect data={info} />
        <Faq section Questions={Questions} faqData={faqData} des={faqDes} />
        {/* <KnowMoreSection nin="Contact Us" Include="services" /> */}
      </Layout>
    </>
  );
};

export default MumbaiPage;

export const Head = () => (
  <Seo
    title="UI UX Design Agency in Mumbai - Octet"
    description="We are the best UI UX design agency in Mumbai. With our expertise and experience, we transform business ideas into design excellence. Contact us now!"
  />
);
